import React from 'react';
import '../Styles/Sidebar.css'




function FooterTemplate()
{

    return (
    <>
        <div className="footer">
        <p></p>
        </div>
    </>
    );
}

export default FooterTemplate;