import React, { useState, useEffect } from 'react';
import Joi from 'joi';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { getEvent } from '../services/eventService';
import { useUserRole } from '../services/userContext';
import AttendeesTable from './attendeesTable';
import Input from './Common/input';
import Select from './Common/select';
import '../Styles/Form.css';
import axios from 'axios';

const EventForm = () =>
{
  const { userRole } = useUserRole();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    eventId: '',
    eventName: '',
    eventDate: '',
    eventTime: '',
    eventLocation: '',
    category: '',
    deleted: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);

  const schema = Joi.object({
    eventId: Joi.number().integer(),
    eventName: Joi.string().required().label('Event Name'),
    eventDate: Joi.date().required().label('Date'),
    eventTime: Joi.string().required().label('Time'),
    eventLocation: Joi.string().required().label('Location'),
    category: Joi.string().required().label('Category'),
    deleted: Joi.number().integer().min(0).max(1).label('Deleted'),
  });

  useEffect(() =>
  {
    const roleNames = userRole.map((role) => ({
      value: role.roleName,
      label: role.roleName
    }));
    setCategories(roleNames);

    const populateEvent = async () =>
    {
      if (id === 'new')
      {
        setIsLoading(false);
        return;
      }
      try
      {
        const { data: event } = await getEvent(id);
        const formattedEvent = {
          ...event[0],
          eventDate: moment(event[0].eventDate).format('YYYY-MM-DDTHH:mm'),
          eventTime: moment(event[0].eventTime, 'HH:mm').format('HH:mm'),
        };
        setData(formattedEvent);
        setIsLoading(false);
      } catch (ex)
      {
        if (ex.response && ex.response.status === 404)
        {
          navigate('/not-found', { replace: true });
        }
        setIsLoading(false);
      }
    };

    populateEvent();
  }, [id, navigate, userRole]);

  const validate = (formData) =>
  {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (!error) return null;

    const newErrors = {};
    for (let item of error.details)
    {
      newErrors[item.path[0]] = item.message;
    }
    return newErrors;
  };

  const validateProperty = ({ name, value }) =>
  {
    const fieldSchema = Joi.reach(schema, name);
    const { error } = fieldSchema.validate(value);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) =>
  {
    e.preventDefault();

    // Filter out any extra properties that are not in the schema
    const formData = {
      eventId: data.eventId,
      eventName: data.eventName,
      eventDate: data.eventDate,
      eventTime: data.eventTime,
      eventLocation: data.eventLocation,
      category: data.category,
      deleted: data.deleted
    };

    const newErrors = validate(formData);
    setErrors(newErrors || {});
    if (newErrors)
    {
      console.log(newErrors);
      return;
    }

    await doSubmit();
  };

  const handleChange = ({ currentTarget: input }) =>
  {
    const newErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage)
    {
      newErrors[input.name] = errorMessage;
    } else
    {
      delete newErrors[input.name];
    }

    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const handleDateTimeChange = (e) =>
  {
    const newData = {
      ...data,
      eventDate: moment(e.target.value).format('YYYY-MM-DDTHH:mm'),
    };
    setData(newData);
  };

  const doSubmit = async () =>
  {
    const apiUrl = process.env.REACT_APP_Attendance_Url;
    const url = `${apiUrl}/Events/${data.eventId}`;
    try
    {
      await axios.put(url, data, {
        baseURL: apiUrl,
      });
      console.log('Event updated successfully.');
    } catch (error)
    {
      console.error('Error updating event:', error);
    }
  };

  if (isLoading)
  {
    return <div>Loading...</div>;
  }
  return (
    <div className="container">
      <h1>Event Form</h1>
      <form onSubmit={handleSubmit}>
        <Input name="id" label="ID" value={data.eventId} onChange={handleChange} error={errors.id} disabled />
        <Input name="eventName" label="Name" value={data.eventName} onChange={handleChange} error={errors.eventName} />
        <Input
          name="eventDate"
          label="Date"
          type="datetime-local"
          value={data.eventDate}
          onChange={handleDateTimeChange}
          error={errors.eventDate}
        />
        <Input name="eventLocation" label="Location" value={data.eventLocation} onChange={handleChange} error={errors.eventLocation} />
        <Select
          name="category"
          label="Category"
          options={categories} // Use the state variable for the options
          value={data.category}
          onChange={handleChange}
          error={errors.category}
        />
        <Select
          name="deleted"
          label="Deleted"
          options={[
            { value: 0, label: 'No' },
            { value: 1, label: 'Yes' }
          ]}
          value={data.deleted}
          onChange={handleChange}
          error={errors.deleted}
        />
        <button>
          Save
        </button>
      </form>
      <AttendeesTable eventId={data.eventId} />
    </div>
  );
};

export default EventForm;
