import React, { Component } from 'react'
import Table from './Common/table'
import { Link } from 'react-router-dom'
import '../Styles/EventsTable.css'
import Pagination from './Common/pagination'
import 'bootstrap/dist/css/bootstrap.css'
import SearchBox from './Common/searchBox'
import { CSVLink } from 'react-csv'

class EventsTable extends Component
{
  state = {
    csvData: [],
    startDate: "2020-01-01",
    endDate: new Date().toISOString().split('T')[0],
  };

  columns = [
    { path: 'ID', label: 'ID', content: event => <Link to={`/events/${event.eventID}`}>{event.eventID}</Link> },
    { path: 'eventName', label: 'Event Name' },
    { path: 'eventDate', label: 'Date', content: event => new Date(event.eventDate).toLocaleDateString() },
    { path: 'eventTime', label: 'Time' },
    { path: 'eventLocation', label: 'Location' },
    { path: 'eventCategory', label: 'Category', content: event => event.category || 'Other' },
    { key: 'delete', content: event => <button onClick={() => this.props.onDelete(event)} className="btn-danger btn-sm">Delete</button> },
  ];

  componentDidMount()
  {
    this.fetchCSVData();
  }

  fetchCSVData = async () =>
  {
    const { startDate, endDate } = this.state;
    const endpoint = `${process.env.REACT_APP_UserEventApi_Url}/aimsReport?startDate=${startDate}&endDate=${endDate}`;
    try
    {
      const response = await fetch(endpoint);
      const jsonData = await response.json();
      const csvData = [
        ['Email', 'Name', 'AIMS Count'],
        ...jsonData.map(event => [event.UserEmail, event.UserFullName, event.aimsCount.toString()]),
      ];
      this.setState({ csvData });
    } catch (error)
    {
      console.error("Failed to fetch or process CSV data:", error);
      this.setState({ csvData: [] });
    }
  };

  handleDateChange = (field, value) =>
  {
    this.setState({ [field]: value }, this.fetchCSVData);
  };

  render()
  {
    const { events, onSort, sortColumn, itemsCount, pageSize, onPageChange, currentPage, searchQuery, onSearchChange, userRole } = this.props;
    const { csvData, startDate, endDate } = this.state;

    return (
      <div className="events-table-wrapper">
        <div className="row">
          <div className="col text-center">
            <h4 style={{ marginTop: 20 }}>Showing {itemsCount} events in the database</h4>
          </div>
          <div className="col text-center">
            <button
              onClick={() =>
              {
                window.location.href = '/events/new'
              }}
              className="btn-primary"
              style={{
                marginBottom: 15,
                marginRight: 15,
                paddingLeft: 10,
                marginTop: 10,
              }}
            >
              New Event
            </button>
          </div>
          <div className="col">
            Start Date:
            <input type="date" value={startDate} onChange={e => this.handleDateChange('startDate', e.target.value)} />
            End Date:
            <input type="date" value={endDate} onChange={e => this.handleDateChange('endDate', e.target.value)} />
            <button onClick={this.fetchCSVData} className="btn btn-primary ml-2">Refresh Data</button>
          </div>
          {userRole && Array.isArray(userRole) && userRole.some(role => role.roleName.includes('Admin')) && (
            <CSVLink data={csvData} filename={"attendees.csv"} target="_blank">
              <button style={{ marginLeft: "5px" }}>AIMS Report</button>
            </CSVLink>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <span style={{ width: '25%' }}>
            <SearchBox value={searchQuery} onChange={onSearchChange} />
          </span>
        </div>
        <Table className="events-table" columns={this.columns} data={events} sortColumn={sortColumn} onSort={onSort} />
        <Pagination itemsCount={itemsCount} pageSize={pageSize} onPageChange={onPageChange} currentPage={currentPage} />
      </div>
    );
  }
}

export default EventsTable;