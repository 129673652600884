import "./Styles/styles.css";
import React, { useEffect } from "react";
import
{
  MsalProvider,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { UserProvider } from "./services/userContext";
import Home from "./Screens/Home";
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
/* import { BrowserRouter as Router, Route, Link } from 'react-router-dom'; */

const App = () =>
{
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isProduction = process.env.NODE_ENV === 'production';
  const { version } = packageJson;

  useEffect(() =>
  {
    if (!isAuthenticated)
    {
      instance
        .ssoSilent({
          scopes: ["user.read", "user.readbasic.All"],
        })
        .then((response) =>
        {
          instance.setActiveAccount(response.account);
        });
    }
  }, []);
  // console.log(version)
  // console.log(isAuthenticated)
  // console.log(isProduction)
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      Loading={false}
      metaFileDirectory={'./var/www/react-frontends/attendanceapp.uamishub.com/html/meta.json'} //If public assets are hosted somewhere other than root on your server.
    >
      <React.Fragment>
        <UserProvider> {/* This is the custom user context provider to store roles globally within the app instead of using session storage*/}
          <Home />
        </UserProvider>
      </React.Fragment>
    </CacheBuster>
  );
};

export default App;
