import { useMsal } from "@azure/msal-react";

// const eventUrl = "https://api.uamishub.com/api/Event";
const eventUrl = process.env.REACT_APP_EventApi_Url;
// const eventUrl = "http://localhost:7228/api/Event";
// const userUrl = "https://api.uamishub.com/api/User";
const userUrl = process.env.REACT_APP_UserApi_Url;
// const userUrl = "https://localhost:7224/api/User";

export const getAttendanceToken = () => {
  const { accounts } = useMsal();
  fetch(eventUrl + "/authenticate", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Id: accounts[0].localAccountId,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      sessionStorage.setItem("attendanceToken", json.token);
    })
    .catch((e) => {
      console.log("attendance token: " + e);
    });
};

export const getUserToken = () => {
  const { accounts } = useMsal();
  fetch(userUrl + "/authenticate", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Id: accounts[0].localAccountId,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      sessionStorage.setItem("userToken", json.token);
    })
    .catch((e) => {
      console.log("user token: " + e);
    });
};
