import React, { useEffect, useState } from "react";
import "../Styles/Sidebar.css";
import "../Styles/TableStyling.css";
import '../Styles/Dropdown.css';
import FooterTemplate from "../Components/Footer";
import { getAttendanceToken } from '../Token';
import { useMsal } from "@azure/msal-react";
import moment from 'moment';

const AttendedEvents = () =>
{
  const { accounts } = useMsal();
  const [attendedEvents, setAttendedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(true);
  const [selected, setSelected] = useState("Selection");



  const handleOpen = () =>
  {
    setOpen(!open);
  };

  const handleClick = (a) =>
  {
    setSelected(a.event.eventName);
    setOpen(!open);
  }

  useEffect(() =>
  {
    const getData = async () =>
    {
      setLoading(true);

      const url = process.env.REACT_APP_UserEventbyID_Url + "/" + `${accounts[0].localAccountId}`;
      // const url = `https://api.uamishub.com/api/UserEvent/${accounts[0].localAccountId}`;

      await fetch(url).then((response) =>
      {
        if (response.status === 401) getAttendanceToken();
        return response.json();
      }).then((json) =>
      {
        console.log(json);
        setAttendedEvents(json);
      }).catch((e) => { console.log(e) }).finally(() =>
      {
        setLoading(false);
      })
    }
    getData();
    console.log(attendedEvents)
  }, [])


  return (
    <>
      <h1>Attended Events</h1>
      {/* <p>Please select a class or category.</p> */}

      <>
        {
          // !loading && (
          // <div>
          //   <button className='dropButton' onClick={handleOpen}>
          //     {selected}
          //   </button>
          //   {(
          //     <ul className="menu">
          //       {attendedEvents.map(event => (
          //         <li className="menu-item" key={event.eventID} onClick={() => handleClick(event)}>
          //           <a>{event.event.eventName}</a>
          //         </li>
          //       ))}
          //     </ul>
          //   )}
          // </div>
          // )
        }
      </>
      <div style={{ marginBottom: "10%" }}>
        <table>
          <tbody>

            {
              attendedEvents.map((a) => (
                <tr key={a.eventID}>
                  <td style={{ fontSize: 'medium' }} >{a.event.eventName}</td>
                  <td style={{ fontSize: 'medium' }}>  {moment(a.event.eventDate).format('MMM, DD YYYY')} @ {a.event.eventTime}</td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </div>
      <FooterTemplate></FooterTemplate>
    </>
  );
};

export default AttendedEvents;
