import React, { useState, useEffect } from 'react'
import Table from './Common/table'
import Pagination from './Common/pagination'
import '../Styles/AttendeesTable.css'
import { CSVLink } from 'react-csv';

function AttendeesTable({ eventId })
{
  const [attendees, setAttendees] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredAttendees, setFilteredAttendees] = useState([])
  const [sortColumn, setSortColumn] = useState({ path: 'name', order: 'asc' })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [newAttendeeEmail, setNewAttendeeEmail] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() =>
  {
    async function fetchAttendees()
    {
      if (typeof eventId === 'number')
      {
        try
        {
          const userResponse = await fetch(process.env.REACT_APP_UserApi_Url);
          const users = await userResponse.json();
          const userEventResponse = await fetch(`${process.env.REACT_APP_UserEventApi_Url}?eventID=${eventId}`);
          const userEvents = await userEventResponse.json();

          const eventAttendeeIds = userEvents.map(
            (eventAttendee) => eventAttendee.attendeeID
          );
          const eventAttendeesDetails = users.filter((user) =>
            eventAttendeeIds.includes(user.id)
          );

          setAttendees(eventAttendeesDetails);
          setFilteredAttendees(eventAttendeesDetails);
        } catch (error)
        {
          console.error('Error:', error);
        }
      }
    }

    fetchAttendees();
  }, [eventId]);

  function handleSearch(event)
  {
    const query = event.target.value
    setSearchQuery(query)

    const filtered = attendees.filter((attendee) =>
    {
      return (
        attendee.name.toLowerCase().includes(query.toLowerCase()) ||
        attendee.email.toLowerCase().includes(query.toLowerCase())
      )
    })

    setFilteredAttendees(filtered)
    setCurrentPage(1) // reset page to 1 after filtering
  }

  function handleSort(sortColumn)
  {
    setSortColumn(sortColumn)
  }

  const columns = [
    { path: 'name', label: 'Name' },
    { path: 'email', label: 'Email' },
  ]

  const totalCount = filteredAttendees.length
  const pageCount = Math.ceil(totalCount / pageSize)

  function handlePageChange(page)
  {
    setCurrentPage(page)
  }

  function generateCSVData()
  {
    const csvData = [
      ['Name', 'Email'], // Headers
      ...filteredAttendees.map((attendee) => [attendee.name, attendee.email]),
    ];

    return csvData;
  }
  const startIndex = (currentPage - 1) * pageSize
  const endIndex = Math.min(startIndex + pageSize, totalCount)
  const pageItems = filteredAttendees.slice(startIndex, endIndex)
  async function handleAddAttendee()
  {
    setSubmitting(true);
    try
    {

      const endpoint = `${process.env.REACT_APP_UserEventApi_Url}/lateUserEvent`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newAttendeeEmail, eventID: eventId }),
      });

      if (response.ok)
      {
        setMessage('Attendee added successfully.');
        // Optionally, refresh the attendees list
      } else
      {
        setMessage('Failed to add attendee.');
      }
    } catch (error)
    {
      console.error('Error:', error);
      setMessage('An error occurred while adding the attendee.');
    }
    setSubmitting(false);
  }

  return (
    <div className="attendees-table-container">

      <div className="text-center">
        <h1>Attendees:

        </h1>
        <div className="add-attendee-container">
          <input
            type="email"
            placeholder="Enter attendee email"
            value={newAttendeeEmail}
            onChange={(e) => setNewAttendeeEmail(e.target.value)}
            disabled={submitting}
          />
          <button onClick={handleAddAttendee} disabled={submitting || !newAttendeeEmail}>
            {submitting ? 'Adding...' : 'Add Attendee'}
          </button>
          {message && <div>{message}</div>}
        </div>
        {filteredAttendees.length > 0 && ( // Show the button only when there are attendees
          <div>
            <h4>Total Attendees: {filteredAttendees.length}
              <CSVLink
                data={generateCSVData()}
                filename={'attendees.csv'}
                target="_blank"
              >
                <button style={{ marginLeft: "5px" }}>Download CSV</button>
              </CSVLink></h4>

          </div>
        )}
        <div>
          <label htmlFor="search-input">Search:</label>
          <input
            type="text"
            id="search-input"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="attendees-table-wrapper" style={{ overflow: 'hidden' }}>
        <Table
          className="attendees-table"
          columns={columns}
          data={pageItems}
          sortColumn={sortColumn}
          onSort={handleSort}
        />
        <div className="pagination-wrapper d-flex justify-content-start">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        </div>
      </div>
    </div>
  );
}

export default AttendeesTable
