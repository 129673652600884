import React, { useEffect, useState } from "react";
import "../Styles/TableStyling.css";
import moment from "moment";


const UpcomingEvents = () =>
{
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = useState("Selection");


  let startDate = new Date();
  startDate.setDate(startDate.getDate() - 1)
  startDate = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate();
  startDate = moment(startDate).format("YYYY-MM-DD")
  // console.log('startDate:', startDate)

  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 1)
  endDate = endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate();
  endDate = moment(endDate).format("YYYY-MM-DD")
  // console.log('endDate:', endDate)


  const handleOpen = () =>
  {
    setOpen(!open);
  };

  const handleClick = (e) =>
  {
    setSelected(e.eventName);
    setOpen(!open);
  }


  useEffect(() =>
  {
    var today = new Date();
    var fullDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    setDate(moment(fullDate).format("YYYY-MM-DD"))
  }, [])

  useEffect(() =>
  {

    console.log("Token: " + sessionStorage.getItem("attendanceToken"));


    const url = process.env.REACT_APP_EventApi_Url;
    // const url = 'https://api.uamishub.com/api/Event';
    // const url = "https://localhost:7058/api/Event"

    fetch(url).then(function (response)
    {
      console.log(response);
      return response.json();
    }).then(function (json)
    {
      console.log(json)
      setUpcomingEvents(json);
    }).catch(function (e)
    {
      console.log(e);
    }).finally(() => { setLoading(false) })

  }, [])

  // upcomingEvents.filter(e => e.eventName >= date)
  // console.log('upcomingEvents:',upcomingEvents)

  return (
    <>
      <h1>Upcoming Events</h1>
      {/* <p>Please select a class or category.</p> */}
      <>
        {
          // !loading ? (
          <div>
            <>
              <button className='dropButton' onClick={() => { handleOpen() }}>{selected}</button>

              <ul className="menu">
                {open ?
                  upcomingEvents.filter(e => e.eventDate >= startDate && e.eventDate <= endDate).map(e => (
                    <li className="menu-item" onClick={() => { handleClick(e) }} key={e.id}>
                      <a key={e.eventID}>{e.eventName}</a>
                    </li>
                  ))
                  : null}
              </ul>
            </>
          </div>
          // ) : null
        }
      </>

      <div style={{ marginBottom: "12%" }}>
        <table style={{ width: "90%" }}>
          <tbody>

            {
              upcomingEvents.filter(e => e.eventDate >= startDate).map(e =>
              {
                return (
                  <tr key={e.id}>
                    <td style={{ width: '33%', fontSize: 'medium' }}>{e.eventName}</td>
                    <td style={{ width: '33%', fontSize: 'medium' }}>{e.eventLocation}</td>
                    <td style={{ width: '33%', fontSize: 'medium' }}>{moment(e.eventDate).format("MMM, DD YYYY")} @ {e.eventTime}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UpcomingEvents;
