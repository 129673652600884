import React, { useState } from 'react';
import
{
  Route,
  Routes,
  Navigate,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import '../Styles/nav.css';
import "../Styles/styles.css";
import { MsalProvider, UnauthenticatedTemplate, useIsAuthenticated, useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import FooterTemplate from '../Components/Footer';
import PageTemplate from "../Components/PageTemplate";
// import Scanned from "./Scanned";
import Scanned from "./Scanned";
import Scanner from "./Scanner";
import UpcomingEvents from "./Upcoming";
import Dashboard from "./Dashboard";
import Login from "./Login";
import ProtectedRoutes from '../Components/Common/protectedRoutes';
import Events from "../Components/Events";
import EventForm from "../Components/eventForm";
import RegisterForm from "../Components/registerForm";
import AttendedEvents from './Attended';

const GetHooks = () =>
{
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return { params, navigate, location };
};

const EventFormWrapper = (props) =>
{
  return <EventForm {...{ ...props, hooks: GetHooks() }} />;
};

const RegisterFormWrapper = (props) =>
{
  return <RegisterForm {...{ ...props, hooks: GetHooks() }} />;
};

const Home = () =>
{

  return (
    <div>
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<PageTemplate />}>
            <Route index element={<Dashboard />} />
            <Route path="Upcoming" element={<UpcomingEvents />} />
            <Route path="Attended" element={<AttendedEvents />} />
            <Route path="*" element={<Dashboard />} />
            <Route path="Scanned" element={<Scanned />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="Scanner" element={<Scanner />} />
              <Route path="events" element={<Events />} />
              <Route path="events/new" element={<RegisterFormWrapper />} />
              <Route path="events/:id" element={<EventFormWrapper />} />
            </Route>
          </Route>
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default Home;