import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import EventsTable from './eventsTable';
import { deleteEvent } from '../services/eventService';
import { paginate } from '../utils/paginate';
import _ from 'lodash';
import { useUserRole } from '../services/userContext';

function Events()
{
  const { userRole } = useUserRole();
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(8);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortColumn, setSortColumn] = useState({ path: 'eventID', order: 'desc' });

  useEffect(() =>
  {

    const url = `${process.env.REACT_APP_Attendance_Url}/Events/byUserRoles`;
    const fetchEvents = async () =>
    {
      const rolesData = {
        roles: userRole
      };
      if (rolesData.roles.length === 0)
      {
        return;
      }
      else
      {
        try
        {
          const response = await fetch(url, { // Adjust with your actual API endpoint
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rolesData),
          });
          if (!response.ok)
          {
            throw new Error('Network response was not ok.');
          }
          const data = await response.json();
          setEvents(data);
        } catch (error)
        {
          toast.error('An error occurred while fetching the events.');
          console.error('Fetching events failed:', error);
        }
      }

    };

    fetchEvents();
  }, [userRole]); // useEffect will re-run if userRole changes

  const handleDelete = async (event) =>
  {
    const originalEvents = events;
    const updatedEvents = originalEvents.filter((e) => e.eventID !== event.eventID);
    setEvents(updatedEvents);
    try
    {
      await deleteEvent(event.eventID);
    } catch (error)
    {
      if (error.response && error.response.data && error.response.data.deleted === 1)
      {
        toast.error('This event has already been deleted.');
      }
      setEvents(originalEvents);
    }
  };

  const handleSort = (sortColumn) =>
  {
    setSortColumn(sortColumn);
  };

  const handlePageChange = (page) =>
  {
    setCurrentPage(page);
  };

  const handleSearch = (query) =>
  {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const getPagedData = () =>
  {
    let filtered = events;

    if (searchQuery)
    {
      filtered = events.filter(
        (e) =>
          (e.eventName && e.eventName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (e.eventDate && e.eventDate.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (e.eventLocation && e.eventLocation.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (e.eventCategory && e.eventCategory.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginatedEvents = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: paginatedEvents };
  };

  const { totalCount, data: activeEvents } = getPagedData();

  return (
    <div className="row">
      <div style={{ marginRight: 15, flex: 2 }}>
        <EventsTable
          events={activeEvents}
          sortColumn={sortColumn}
          onDelete={handleDelete}
          onSort={handleSort}
          itemsCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          searchQuery={searchQuery}
          onSearchChange={handleSearch}
          userRole={userRole}
        />
      </div>
      <ToastContainer position="top-center" autoClose={5000} />
    </div>
  );
}

export default Events;
