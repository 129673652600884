import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import "../Styles/Sidebar.css";
import "../Styles/nav.css";
import "../Styles/Dashboard.css";
import FooterTemplate from "../Components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import QRcode from "qrcode.react";


const Dashboard = () =>
{
  const { accounts } = useMsal();
  if (!accounts[0])
  {
    return (
      <>
        <h1 style={{ fontSize: 'xx-large' }}>Dashboard</h1>
        <p style={{ fontSize: 'x-large' }} >You Don't have an account in our system! Please let your Lead TA, Program Support or LabMX Know</p>
        <FooterTemplate></FooterTemplate>
      </>
    );
  }
  const [qr] = useState(accounts[0].localAccountId);



  return (
    <>
      <h1 style={{ fontSize: 'xx-large' }}>Dashboard</h1>
      <p style={{ fontSize: 'x-large' }} >Welcome! Please scan your QR code.</p>

      <QRcode
        size={250}
        value={qr}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
        }}
      />

      <FooterTemplate></FooterTemplate>
    </>
  );
};

export default Dashboard;