import React from "react";
import ReactDOM from "react-dom/client";
import "./Styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";

console.log(process.env.REACT_APP_NAME);

// export default function Apps() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<PageTemplate />}>
//           <Route index element={<QRCoder />} />
//           <Route path="Upcoming" element={<UpcomingEvents />} />
//           <Route path="Attended" element={<AttendedEvents />} />
//           <Route path="QRCode" element={<QRCoder />} />
//           <Route path="Scanned" element={<Scanned />} />
//           <Route path="*" element={<Scanner />} />
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   );
// }

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);

// root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
