import React from 'react';
import _ from 'lodash';

const TableBody = ({ data, columns }) =>
{
    const renderCell = (item, column) =>
    {
        if (column.content) return column.content(item);
        return _.get(item, column.path);
    };

    // Ensuring unique keys for each cell
    const createKey = (item, column, columnIndex) =>
    {
        return item.id + (column.path || column.key || columnIndex);
    };

    return (
        <tbody>
            {data.length !== 0 &&
                data.map((item, rowIndex) => (
                    <tr key={item.id || rowIndex}>
                        {columns.map((column, columnIndex) => (
                            <td key={createKey(item, column, columnIndex)}>
                                {renderCell(item, column)}
                            </td>
                        ))}
                    </tr>
                ))}
        </tbody>
    );
};

export default TableBody;
