import http from './httpService'

const apiUrl = process.env.REACT_APP_EventApi_Url

function eventUrl(id)
{
  return `${apiUrl}/${id}`
}

export function register(event)
{
  return http.post(apiUrl, {
    eventName: event.eventName,
    eventDate: event.eventDate,
    eventTime: event.eventTime,
    eventLocation: event.eventLocation,
    category: event.eventCategory,
    eventDeleted: event.eventDeleted,
  })
}

export function getEvents()
{
  return http.get(apiUrl)
}

export function getEvent(eventID)
{
  return http.get(eventUrl(eventID))
}

export function saveEvent(event)
{
  if (event.id)
  {
    const body = { ...event }
    if (body.eventDeleted === 'Not Deleted')
    {
      body.eventDeleted = 0
    } else if (body.eventDeleted === 'Deleted')
    {
      body.eventDeleted = 1
    }
    return http.put(eventUrl(event.id), body)
  } else
  {
    if (event.eventDeleted === 'Not Deleted')
    {
      event.eventDeleted = 0
    } else if (event.eventDeleted === 'Deleted')
    {
      event.eventDeleted = 1
    }
    return http.post(apiUrl, event)
  }
}

export function deleteEvent(eventID)
{
  return http.delete(eventUrl(eventID))
}
