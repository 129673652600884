import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useUserRole } from "../services/userContext";
import { getUserToken } from '../Token';
import { Outlet, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../Styles/Sidebar.css";

function PageTemplate(props)
{
  const { accounts } = useMsal();
  const { setUserRole } = useUserRole();
  const [expanded, setExpanded] = useState(false);
  const { instance } = useMsal();
  const { userRole } = useUserRole();


  useEffect(() =>
  {
    const fetchRole = async () =>
    {
      const url = process.env.REACT_APP_User_Url + "/Users/" + `${accounts[0].localAccountId}`;

      try
      {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionStorage.getItem("userToken"),
          }
        });

        if (response.status !== 401)
        {
          const json = await response.json();
          // sessionStorage.setItem("userRole", json[0].role);
          setUserRole(json.roles); // use context method instead of sessionStorage (keeping sessionStorage for now)
        } else
        {
          getUserToken();
          throw new Error("Unauthorized");
        }
      } catch (error)
      {
        console.log(error);
      } finally
      {
      }
    };

    fetchRole();
  }, []);

  const handleLogout = () =>
  {
    instance.logoutRedirect().catch(e => console.error(e));
  };

  const renderNavLinks = () =>
  {
    const commonLinks = [
      { to: "/", label: "Home" },
      { to: "/QRCode", label: "Dashboard" },
      { to: "/Upcoming", label: "Upcoming" },
      { to: "/Attended", label: "Attended" },
    ];

    const adminLinks = [...commonLinks, { to: "/Scanner", label: "Scan" }, { to: "/events", label: "Admin Portal" }];
    const studentLinks = [...commonLinks];

    const isAdmin = userRole.length > 0;
    const linksToRender = isAdmin ? adminLinks : studentLinks;

    return linksToRender.map(link => (
      <h5 key={link.to} id="navH5">
        <Nav.Link>
          <Link to={link.to} id="navC" onClick={() => setExpanded(false)}>
            {link.label}
          </Link>
        </Nav.Link>
      </h5>
    ));
  };

  return (
    <>
      <div className="maindash">{props.children}</div>
      <Navbar expand="lg" expanded={expanded}>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ backgroundColor: "white", marginLeft: "1%", border: "0", marginTop: "0" }}
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav" style={{ zIndex: 2 }}>
          <Nav style={{ marginTop: "1%", backgroundColor: "maroon", width: "100%" }}>
            {renderNavLinks(userRole)}
            <h5 id="navH5">
              <Nav.Link style={{ color: "white" }}>
                <a id="navC" style={{ alignContent: "end" }} onClick={handleLogout}>
                  Logout
                </a>
              </Nav.Link>
            </h5>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </>
  );
}

export default PageTemplate;
