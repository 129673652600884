import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getCurrentUser } from '../../services/authService';

const ProtectedRoutes = () =>
{
  // const location = useLocation();
  // const [currentUser, setCurrentUser] = useState(null);
  //test
  // useEffect(() =>
  // {
  //   async function fetchData()
  //   {
  //     const user = await getCurrentUser();
  //     setCurrentUser(user);
  //   }
  //   fetchData();
  // }, []);

  // if (!currentUser)
  // {
  //   // Render a loading spinner or some other indication that the user is being fetched
  //   return <div>Loading...</div>;
  // }

  // const isUserAdmin = currentUser.role === 'admin';
  // const isUserOnScanner = location.pathname.startsWith('/Scanner');
  // const isUserOnEventsPortal = location.pathname.startsWith('/events');

  // console.log('location', location);
  // console.log('currentUser', currentUser);
  // console.log('isUserAdmin', isUserAdmin);
  // console.log('isUserOnScanner', isUserOnScanner);
  // console.log('isUserOnEventsPortal', isUserOnEventsPortal);

  // if (isUserAdmin && (isUserOnScanner || isUserOnEventsPortal)) {
  //   return <Outlet />;
  // } else {
  //   return <Navigate to="/unauthorized" replace />;
  // }
  return <Outlet />;
};

export default ProtectedRoutes;
