import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { register } from '../services/eventService';
import { useUserRole } from '../services/userContext';
import '../Styles/Form.css';

const RegisterForm = () =>
{
  const { userRole } = useUserRole();
  const [data, setData] = useState({
    id: '',
    eventName: '',
    eventDate: '',
    eventTime: '',
    eventLocation: '',
    eventCategory: '',
    eventDeleted: '0',
  });
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() =>
  {
    const isAdd = userRole.some(role => role.roleName === "Admin");
    if (userRole.length > 0 && isAdd === true)
    {
      setCategories(["AIMS", "221", "321", "330", "430/520", "405/505", "421/521", "460/561", "462/563", "WIT", "Ambassadors", "MMIS", "CMISS", "VIB", "LGBTQ+", "Affinity (Joint Events)", "Other"])
    }
    else if (userRole.length > 0 && isAdd === false)
    {
      setCategories(userRole.map((role) => role.roleName))
    }
    // userRole.length > 0 && userRole.includes("Admin") ? : setCategories(userRole.map((role) => role.roleName))
  }, [userRole])

  // useEffect(() =>
  // {
  // const url = `${process.env.REACT_APP_Attendance_Url}/Events/byUserRoles`;
  // const fetchCategories = async () =>
  // {
  //   try
  //   {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ roles: userRole.map((role) => role.roleName) }),
  //     });
  //     if (!response.ok)
  //     {
  //       throw new Error('Network response was not ok.');
  //     }
  //     const fetchedData = await response.json();
  //     setCategories(fetchedData.map((cat) => ({ label: cat.roleName, value: cat.roleId })));
  //   } catch (error)
  //   {
  //     toast.error('An error occurred while fetching categories.');
  //     console.error('Fetching categories failed:', error);
  //   }
  // };

  // fetchCategories();
  // }, [userRole]);

  const validateForm = () =>
  {
    const newErrors = {};
    if (!data.eventName) newErrors.eventName = "Event Name is required";
    if (!data.eventDate) newErrors.eventDate = "Event Date is required";
    if (!data.eventTime) newErrors.eventTime = "Event Time is required";
    if (!data.eventLocation) newErrors.eventLocation = "Event Location is required";
    if (!data.eventCategory) newErrors.eventCategory = "Event Category is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleChange = ({ currentTarget: input }) =>
  {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };
  const handleSubmit = async (e) =>
  {
    e.preventDefault();

    if (!validateForm()) 
    {
      toast.error('Registration failed, please check the form for missing fields.');
      return;
    }

    try
    {
      await register(data);
      toast.success('Event registered successfully');
    } catch (ex)
    {
      if (ex.response && ex.response.status === 400)
      {
        toast.error('Registration failed');
        setErrors({ ...errors, ...ex.response.data });
      }
    }
  };

  return (
    <div className="container">
      <h1>New Event Form</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="eventName">Event Name</label>
          <input
            name="eventName"
            id="eventName"
            value={data.eventName}
            onChange={handleChange}
            className={`form-control ${errors.eventName ? 'is-invalid' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="eventDate">Event Date</label>
          <input
            name="eventDate"
            id="eventDate"
            type="date"
            value={data.eventDate}
            onChange={handleChange}
            className={`form-control ${errors.eventDate ? 'is-invalid' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="eventTime">Event Time</label>
          <input
            name="eventTime"
            id="eventTime"
            type="time"
            value={data.eventTime}
            onChange={handleChange}
            className={`form-control ${errors.eventTime ? 'is-invalid' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="eventLocation">Event Location</label>
          <input
            name="eventLocation"
            id="eventLocation"
            value={data.eventLocation}
            onChange={handleChange}
            className={`form-control ${errors.eventLocation ? 'is-invalid' : ''}`}
          />
        </div>
        <div className="form-group">
          <label htmlFor="eventCategory">Event Category</label>
          <select
            name="eventCategory"
            id="eventCategory"
            value={data.eventCategory}
            onChange={handleChange}
            className={`form-control ${errors.eventCategory ? 'is-invalid' : ''}`}
          >
            <option value="">Select Category</option>
            {categories.map(category => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          {errors.eventCategory && <div className="alert alert-danger">{errors.eventCategory}</div>}
        </div>
        <div className="form-group">
          <button type="submit" >
            Save
          </button>
        </div>
      </form>
      <ToastContainer position="top-center" autoClose={5000} />
    </div>
  );
};

export default RegisterForm;
