import { PublicClientApplication } from "@azure/msal-browser";
import http from './httpService';
import { msalConfig } from "../authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const apiUrl = process.env.REACT_APP_UserApi_Url;

async function getCurrentUser() {
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      throw new Error('No accounts found');
    }
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0], // Set the account object for the request
    };
    // console.log("account:", accounts[0])
    const authResult = await msalInstance.acquireTokenSilent(accessTokenRequest);
    const { account } = authResult;
    msalInstance.setActiveAccount(account);
    const { data } = await http.get(`${apiUrl}/${account.localAccountId}`, {
      headers: { Authorization: `Bearer ${authResult.accessToken}` },
    });
    // console.log(`${apiUrl}/${account.localAccountId}`)      
    // console.log("id:", account.localAccountId, "name:", account.name, "email:", account.username, "role:", data[0].role);
    return {
      id: account.localAccountId,
      name: account.name,
      email: account.username,
      role: data[0].role,
    };
  } catch (ex) {
    console.error(ex);
    return null;
  }
}
 


export { getCurrentUser };
