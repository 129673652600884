import { useMsal } from '@azure/msal-react'
import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useWindowDimensions from '../useWindowDimensions.js'
import { getAttendanceToken, getUserToken } from '../Token'
import FooterTemplate from '../Components/Footer'
import moment from 'moment'
import { useUserRole } from '../services/userContext';

const Scanner = () =>
{
  const { userRole } = useUserRole();

  const { accounts } = useMsal();
  const authenticatedUser = accounts[0];
  const scannerID = authenticatedUser ? authenticatedUser.localAccountId : null;
  const [selected, setSelected] = useState('environment')
  const [scanning, setScanning] = useState(false)
  const [students, setStudent] = useState([])
  const [event, setEvent] = useState('')
  const [events, setEvents] = useState([])
  const [timeout, setTime] = useState(false)
  const [timedOut, setTimedOut] = useState(false)
  const [success, setSuccess] = useState(false)
  const [mobile, setMobile] = useState()
  const { height, width } = useWindowDimensions()
  const [scannedUsers, setScannedUsers] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([]);


  let student
  let processing = false
  let count = 0

  // const eventUrl = "https://localhost:7058/api/Event";
  // const userEventUrl = "https://localhost:7058/api/UserEvent/";

  const eventUrl = process.env.REACT_APP_EventApi_Url
  // const eventUrl = "https://api.uamishub.com/api/Event";
  const userEventUrl = process.env.REACT_APP_UserEventApi_Url
  // const userEventUrl = "https://api.uamishub.com/api/UserEvent";

  useEffect(() =>
  {
    const roleNames = userRole.map((role) => role.roleName);
    const eventsFilteredByRole = events.filter((event) =>
      roleNames.includes(event.category)
    );

    setFilteredEvents(eventsFilteredByRole);
    if (roleNames.includes("Admin"))
    {
      setFilteredEvents(events);
    }
  }, [userRole, events])

  useEffect(() =>
  {
    if (height > 900 && width >= 600)
    {
      setMobile(false)
    } else if (height < 900 && width <= 600)
    {
      setMobile(true)
    }
  }, [])

  useEffect(() =>
  {
    fetch(eventUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('attendanceToken'),
      },
    })
      .then(response =>
      {
        if (response.status === 401) getAttendanceToken()
        if (response.status != 401) return response.json()
        throw Error
      })
      .then(json =>
      {
        const upcomingEvents = json.filter(e =>
        {
          const eventDate = moment(e.eventDate, 'YYYY-MM-DD')
          const yesterday = moment().subtract(1, 'days')
          const tomorrow = moment().add(1, 'days')
          return eventDate.isBetween(yesterday, tomorrow, 'day', '[]')
        })
        var events = upcomingEvents.sort((a, b) => Date.parse(a.eventDate) - Date.parse(b.eventDate))
        setEvents(events)
        const now = moment();
        const closestEvent = events.reduce((prev, curr) =>
        {
          const prevDiff = now.diff(moment(prev.eventDate));
          const currDiff = now.diff(moment(curr.eventDate));

          if (currDiff > 0 && prevDiff < 0) return curr; // prioritize upcoming events in scanner dropdown menu
          else if (currDiff > 0 && prevDiff > 0) return (currDiff < prevDiff) ? curr : prev; // choose closer future event
          else return (Math.abs(prevDiff) < Math.abs(currDiff)) ? prev : curr; // default to closer in absolute terms
        });
        setEvent(closestEvent.id);
      })
      .catch(error =>
      {
        console.log(error)
      })
  }, [])


  useEffect(() =>
  {
    setTimeout(() =>
    {
      setTime(false)
    }, 1500)
  }, [timeout])

  useEffect(() =>
  {
    if (timedOut === true) notify(student, 'warning')
    setTimeout(() =>
    {
      if (timedOut === true) setTimedOut(false)
    }, 1500)
  }, [timedOut])

  const notify = (student, type) =>
  {
    //Display's notification depending on what the type (string) variable contains

    switch (type)
    {
      case 'success':
        toast.success('Name: ' + student.name, {
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        break
      case 'warning':
        toast.warn('Wait to scan', {
          position: 'top-center',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        break
      default:
        toast.error(type, {
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        break
    }
  }

  const postEvent = async (student) =>
  {
    fetch(userEventUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventID: event == '' ? events[0].id : event,
        attendeeID: student.id,
        scannerID: scannerID,
      }),
    })
      .then((response) =>
      {
        console.log(response);
        if (response.status === 401) getAttendanceToken();
        if (response.status === 200) notify(student, 'success');
      })
      .catch((e) =>
      {
        notify(student, e.message);
      });
  }


  const handleScan = async (scanData) =>
  {
    //Determines if the there is a timeout, and if there is actually a scan coming in, if so adds one to count

    if (timeout === true && scanData && scanData != '') count++ //Main logic for scanning, handles taking the user and inputting it into the database, calls postEvent to actually send to database //This retrieves from database and makes sure user exists

    if (
      scanData &&
      scanData !== '' &&
      timeout !== true &&
      !processing &&
      !scannedUsers.includes(scanData)
    )
    {
      setSuccess(false)

      processing = true

      const userUrl = process.env.REACT_APP_ScanData_Url + '/' + `${scanData}` // const userUrl = `https://api.uamishub.com/api/User/${scanData}`; // const userUrl = `http://localhost:7224/api/User/${scanData}`;

      await fetch(userUrl, {
        method: 'GET',

        headers: {
          Accept: 'application/json',

          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
        .then(function (response)
        {
          console.log(response)

          if (response.status === 401) getUserToken()

          return response.json()
        })

        .then(function (json)
        {
          console.log('Getting Attendee: ' + json)

          setStudent(json[0])

          setTime(true)

          setSuccess(true)

          student = {
            id: json[0].id,

            name: json[0].name,

            email: json[0].email,
          }
        })

        .catch(function (error)
        {
          notify(student, error.message)
        })

        .finally(() =>
        {
          processing = false

          count = 0
        })

      postEvent(student)
    } //The count here allows for human error "3 scans" before display an alert message
    else if (timeout === true && !timedOut && count > 3)
    {
      setTimedOut(true)

      count = 0
    }

    setScannedUsers([...scannedUsers, scanData])
  }

  const handleError = (err) =>
  {
    notify(student, err.message)
  }

  // const userRole = sessionStorage.getItem("userRole");
  // if (userRole !== "admin")
  // {
  //   return
  // }
  return (
    <div>
      {scanning ? (
        <>
          <ToastContainer rtl={false} pauseOnFocusLoss pauseOnHover />
          {mobile === true ? <div style={{ marginTop: '15%' }}></div> : null}
          <div>
            {mobile ? (
              <QrReader
                facingMode={selected}
                delay={250}
                onError={handleError}
                onScan={handleScan}
              />
            ) : (
              <div
                style={{
                  marginTop: '2%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <QrReader
                  facingMode={selected}
                  delay={250}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '40%', height: 'auto' }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              width: '90%',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              onClick={() =>
              {
                setScanning(false)
                console.log(event)
              }}
            >
              Exit
            </button>
          </div>
        </>
      ) : (
        <>
          <h1>Scan</h1>
          <p>Select a class or event</p>
          <div
            style={{
              display: 'flex',
              marginLeft: '5%',
              position: 'relative',
            }}
          >
            <button
              style={{
                fontWeight: '500',
                borderColor: 'black',
                borderStyle: 'solid',
                borderWidth: '2.5px',
                margin: '0',
                borderRadius: '0',
                fontSize: '200%',
                fontFamily: 'Poppins',
                padding: '10px',
                marginTop: '1%',
                paddingLeft: '20px',
                paddingRight: '20px',
                boxShadow: '3px 3px 10px gray',
              }}
              onClick={() =>
              {
                setEvent(document.getElementById('event').value)
                setScanning(true)
              }}
            >
              Scan
            </button>
            {/* <br></br> */}
          </div>
          <div
            style={{
              width: '90%',
              display: 'flex',
              marginLeft: '5%',
              position: 'relative',
            }}
          >
            <select
              id="event"
              style={{
                borderColor: 'black',
                borderStyle: 'solid',
                borderWidth: '2px',
                margin: '0',
                borderRadius: '0',
                fontSize: '150%',
                fontFamily: 'Poppins',
                padding: '7px',
                marginTop: '1%',
              }}
              value={event}
              onChange={e => setEvent(e.target.value)}
            >
              {filteredEvents.map((e) => (
                <option value={e.eventId} key={e.eventId}>
                  {e.eventName + ' - ' + moment(e.eventDate).format('MM/DD')}
                </option>
              ))}
            </select>
          </div>
          <FooterTemplate></FooterTemplate>
        </>
      )
      }
    </div >
  )
}

export default Scanner
