import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import QRcode from "qrcode.react";
import { useMsal } from "@azure/msal-react";
import FooterTemplate from "../Components/Footer";

const Scanned = () =>
{
  const { accounts } = useMsal();
  const [qr, setQr] = useState(accounts[0].localAccountId);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <QRcode
          value={12734891}
          size={420}
          includeMargin={true}
          style={{ marginLeft: 200 }}
        />
      </div>
      <FooterTemplate></FooterTemplate>
    </>
  );
};

export default Scanned;